.date-range-selector {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    color: rgba(255, 255, 255, 0.55);
}

.date-range-selector label {
    padding-right: 12px;
}

.date-range-selector input[type="date"],
.date-range-selector input[type="datetime-local"] {
    width: 200px;
    margin-right: 32px;
    margin-bottom: 0;
    color: rgba(200, 255, 155, 0.55);
    color-scheme: dark;
}

::-webkit-calendar-picker-indicator {
}

.date-range-selector button.date-range-selector-button {
    padding: 0 32px;
    height: 45px;
}

.date-range-selector button.date-range-selector-button:hover {
    background-color: var(--button-hover-color);
}
