#user-details-body { 
}

#user-details-body > div {
    padding-top: 24px;
}

#user-details-body ul { 
    padding: 0;
    margin: 0;
    display: block;
    list-style: none;
}

#user-details-body ul li {
    padding: 8px 0;
}

#user-submenu div.image {
    text-align: center;
    padding-top: 24px;
    margin-right: 12px;
    margin-left: 12px;
}

#user-submenu div.profile { 
}

#user-submenu div.profile #user-profile-name {
    padding-top: 8px;
    padding-bottom: 4px;
}

#user-submenu div.image span.image { 
    display: inline-block;
    border-radius: 100px;
    align-content: center;
    align-items: center;
    text-align: center;
    min-width: 128px;
    height: 128px;
    
    background-color: rgba(0, 0, 0, 0.4);
    border: rgba(255, 255, 255, 0.2) 8px solid;
}

#user-submenu div.image img {
    width: 128px;
    height: 128px;
    border-radius: 100px;
    border: rgba(255, 255, 255, 0.2) 8px solid;
}

#user-submenu div.image svg { 
    height: 64px;
    background-color: rgba(0, 0, 0, 0.4);
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.75);
    align-self: center;
    display: inline-block;
}

table tr.current td,
table tr.current td a { 
    color: var(--header-green);
}



#user-activity-body table tr { 
    color: var(--text-color);
}

#user-activity-body table tr:hover {
    color: white;
}

#user-activity-body table tr td span.icon { 
    width: 32px;
    display: inline-block;
}

#user-activity-body table tr th.activity-text-td,
#user-activity-body table tr td.activity-text-td {
    width: 78%;
}

#user-activity-body table tr th.activity-date-td,
#user-activity-body table tr td.activity-date-td {
    width: 30%;
}

.date-activity { 
    display: block;
    margin-bottom: 12px;
    padding-bottom: 12px;
}

.date-activity span { 
    display: inline-block;
    margin-right: 32px;
    font-size: 15px;
    color: var(--text-color);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
}

.date-activity span svg { 
    margin-right: 8px;
    color: var(--header-green);
}

.date-activity span #user-created-date {
    
}

