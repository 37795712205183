span.tool-tipper { 
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

span.tool-tipper span.tool-tipper-controller { 
    /* Children/Content */
}

span.tool-tipper .tool-tip {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 256;
}

span.tool-tipper .tool-tip .tool-tip-body {
    display: block;
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    border-radius: 5px;
    padding: 8px 16px;
    margin-bottom: 0;
    font-size: 14px;
}

span.tool-tipper .tool-tip .tool-tip-caret { 
    display: flex;
    text-align: center;
    align-content: center;
    align-items: start;
    justify-content: center;
    height: 6px;
    
    margin-right: auto;
    margin-left: auto;
    margin-top: 0;
}

span.tool-tipper .tool-tip .tool-tip-caret .tool-tip-caret-tri {
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;    
}

span.tool-tipper .tool-tip.hide {
    display: inline-block;
    opacity: 0.0;
}

span.tool-tipper .tool-tip.show { 
    display: inline-block;
    transition: 300ms;
    transition-property: display;
    margin-bottom: 24px;
}
