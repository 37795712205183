.bad-se-number { 
    color: var(--warning-red);
}

td > span.error { 
    font-size: 12px;
    color: var(--warning-red);
}

.no-amex { 
    display: block;
    margin-top: 32px;
    font-size: 13px;
}

.no-amex > a { 
    color: var(--text-color);
}

.no-amex > a:hover { 
    color: white;
}

div.amex {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-bottom: 48px;
    margin-top: 32px;
    gap: 32px;
}

div#batch-results.details-body div.amex > p { 
    margin-bottom: 0;
    margin-right: 100px;
    margin-top: 0;
}

div.amex > * { 
    
}

div.amex > span:first-child > svg {
    color: white;
    stroke: white;
    fill: #6e96ff;
    height: 48px;
}

div.amex > p {
    
}

.se-form { 
    width: 420px;
}