
table.plans { 
    
}

table.plans > thead > tr > th:first-child,
table.plans > tbody > tr > td:first-child { 
    width: 40%;
}

td.plan-detail-icons { 
    
}

td.plan-detail-icons svg {
    margin-left: 16px;
}

td.plan-detail-icons svg.pool {
    color: #00b8d4;
}

td.plan-detail-icons svg.mrc-tiers {
    color: #00d4b8;
}

td.plan-detail-icons svg.transaction-tiers {
    color: mediumpurple;
}

td.plan-detail-icons svg.minimum {
    color: #d4b800;
}

.tiers-section { 
    margin-top: 32px;
}

.tiers-section > h3 {
    padding-bottom: 12px;
    border-bottom: var(--border-color) 1px solid;
}

.tiers-section .row { 

}

div.tier-note { 
    padding-left: 200px;
    padding-top: 0;
    color: var(--yellow);
}

.form .form-group-columns > .tier-row > div.tier-note:last-child {
    color: var(--yellow);
    padding-top: 6px;
    margin-bottom: 24px;
}

.form .form-group-columns > .tier-row span.remove { 
    margin-left: 16px;
}

.form .form-group-columns > .tier-row span.remove svg {
    display: none;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    color: var(--error-red);
}

.form .form-group-columns > .tier-row:hover span.remove svg { 
    display: initial;
}

div.tier-note > .ui-span { 
    margin-right: 5px !important;
    display: inline-block;
}