.partner-activity.batch-submission { 
    display: block;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 12px;
    margin-bottom: 24px;
}

.batch-submission.results {
    background-color: rgba(0, 24, 0, 0.4);
}

#batch-activity { 
    display: block;
    width: 944px;
    padding-top: 28px;
}

#batch-activity h3 { 
    padding-bottom: 8px;
}

#batch-activity .batch-submission-total-count { 
    float: right;
    margin: 16px 24px 0 0;
    padding: 0 0 0 24px;
    border-left: rgba(255, 255, 255, 0.1) solid 1px;
    font-size: 38px;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
}

#batch-activity .batch-submission-total-count a {
    color: rgba(255, 255, 255, 0.75);
}

#batch-activity .batch-submission-total-count a:hover {
    color: white;
}

#batch-activity .batch-submission-total-count label { 
    display: block;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.55);
}

#batch-activity h4,
#batch-activity h4 a {
    color: rgba(255, 255, 255, 0.75);
    margin-bottom: 18px;
}

#batch-activity h4 a:hover { 
    color: white;
    text-decoration: underline;
}

#batch-activity h4 label { 
    display: block;
    padding-left: 30px;
    font-size: 12px;
    padding-top: 6px;
    color: rgba(255, 255, 255, 0.55);
}

#batch-activity .results h4 label {
    padding-left: 26px;
}

#batch-activity .results h4 {
    color: #AAfeA5;
}

#batch-activity .results h4 svg {
    color: #00fe35;
}

#batch-activity h4 .batch-date { 
    display: inline-block;
    margin-left: 32px;
    font-size: 12px;
    font-weight: bold;
}

#batch-activity h4 .batch-status-element { 
    display: inline-block;
    margin-left: 32px;
}

#batch-activity h4 .batch-status-element svg {
    color: #CCCCCCAA;
}

#batch-activity .processing h4 .batch-status-element svg {
    color: #00fe35AA;
}

#batch-activity h4 svg { 
    margin-right: 12px;
    color: #cccccc;
}

#batch-activity .card-brands { 
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    gap: 12px;
}

#batch-activity .card-brands span.card { 
    display: inline-block;
    min-width: 78px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

#batch-activity .card-brands span span.content { 
    padding: 4px 12px 4px 32px;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
}

#batch-activity .card-brands span span.content span.svg {
    opacity: 0.65;
}

#batch-activity .card-brands span span.content span.value { 
    padding-top: 8px;
    padding-bottom: 8px;
}

#batch-activity .card-brands span span.content svg { 
    margin-right: 12px;
}

#batch-activity .card-brands span.visa {
    
}

#batch-activity .card-brands span.mastercard {
}

#batch-activity .card-brands span.discover {
}

#batch-activity .card-brands span.amex {
}

#partner-merchant-details .section { 
    padding-top: 16px;
    padding-bottom: 16px;
}

#partner-merchant-details .section .rows {

}

ul li.excel,
#partner-merchant-details .section .rows ul li {
    text-align: left;
    padding: 8px 0;
    color: rgba(255, 255, 255, 0.75);
}

#partner-merchant-details .section .rows ul li:first-child {
    color: var(--header-green);
    font-size: unset !important;
}

#partner-merchant-details .section span.separator {
    margin-top: 8px;
    min-height: 76px;
}

#partner-merchant-details .section .rows ul li#enrollment-card-counts {
    padding-bottom: 16px;
    padding-top: 16px;
    display: block;
}

.details-body .section.merchant-details-section { 
    margin-bottom: 48px;
}

.merchant-details-section span.icon {
    text-align: center;
    align-content: center;
    width: 120px;
}

.merchant-details-section > span svg,
li.excel span svg { 
    margin: 24px;
}

.merchant-details-section ul li.date svg { 
    margin-right: 8px;
}

.partner-excel { 
    padding-top: 48px;
}

ul li.approved svg { 
    color: var(--approved-color);
}

ul li.completed svg {
    color: var(--header-green);
}

ul li.excel { 
    min-width: 600px;
}

div.excel-small { 
    font-size: 12px;
    max-width: 280px;
}

div.excel-small a > span:last-child { 
    width: 16px;
    margin-left: 8px;
    margin-right: 12px;
    display: inline-block;
}

div.excel-small a span { 
    word-wrap: anywhere;
}

div.excel-small a,
ul li.excel a {
    display: flex;
    position: relative;
    align-content: center;
    justify-content: start;
    align-items: center;
    background-color: rgba(120, 255, 120, 0.05);
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 12px;
    margin-bottom: 12px;
    border: #00fe3511 1px solid;
    border-radius: 8px;
}

div.excel-small a span svg {
    margin: 12px;
    color: var(--text-color-faded);
}

div.excel-small a {
    color: var(--text-color);
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
}

div.excel-small a:hover span,
div.excel-small a:hover span svg{
    color: white;
}

div#partner-enrollments .details-body .pad p {
    margin-right: 280px;
}


li.excel svg {
    color: rgba(255, 255, 255, 0.75);
}

ul li.excel a span.loading-icon { 
    position: relative;
    padding-left: 16px;
}

ul li.excel a:hover {
    background-color: rgba(60, 2405, 60, 0.25);
}

ul li.excel span {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
}

#enrollment-card-counts span {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 8px 14px 8px 12px;
    border-left: transparent solid 4px;
    margin-right: 16px;
    color: rgba(255, 255, 255, 0.7);
}

#enrollment-card-counts span.visa {
    border-left-color: var(--visa-color);
}

#enrollment-card-counts span.mastercard {
    border-left-color: var(--mastercard-color);
}

#enrollment-card-counts span.discover {
    border-left-color: var(--discover-color);
}

#enrollment-card-counts span.amex {
    border-left-color: var(--amex-color);
}

tr.enrollment-0 .name a svg {
    width: 12px;
}

tr.enrollment-1 .name a svg {
    color: var(--fiserv-color);
    margin-left: 3px;
    width: 8px;
}

tr.enrollment-2 .name a svg {
    color: var(--worldpay-color);
    width: 12px;
}

.new-enrollments {
    clear: both;
    margin-bottom: 56px;
    padding: 32px 32px 16px 32px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}

.new-enrollments h3 {
}

.new-enrollments h3 svg { 
    margin-right: 16px;
    color: var(--paylani-green);
    opacity: 1.0;
}

.new-enrollments table.table-large { 
    
}

.new-enrollments table.table-large { 
    font-size: 13px;
}

.new-enrollments table.table-large tbody tr td { 
    padding: 8px;
}

.new-enrollments table.table-large tbody tr .name {
    width: 50%;
}

.new-enrollments table.table-large tbody tr .date {
    width: 300px;
}

.new-enrollments table.table-large tbody tr .check {
    padding: 1px;
}

.new-enrollments table.table-large tbody tr .check span.ui-span { 
    align-content: center;
    justify-content: center;
}

.new-enrollments table.table-large tbody tr .check span.ui-span a { 
    align-content: center;
    justify-content: center;
}

#partner-enrollments table thead tr th.name,
#partner-enrollments table tbody tr td.name { 
    width: 45%;
    overflow: hidden;
    word-wrap: break-word;
    white-space: nowrap;
}

#partner-enrollments table thead tr th.mid,
#partner-enrollments table tbody tr td.mid {
    width: 30%;
}

#partner-enrollments table thead tr th.date,
#partner-enrollments table tbody tr td.date {
    width: 25%;
}

#partner-enrollments table tbody tr .email svg { 
    margin-right: 8px;
    color: rgba(255, 255, 255, 0.5);
}

#partner-enrollments table tbody tr .email a:hover svg { 
    color: var(--paylani-green);
}

.new-enrollments table.table-large tbody tr .check svg { 
    height: 18px;
    display: inline-block;
}

.new-enrollments table.table-large tbody tr td:last-child,
.new-enrollments table.table-large tbody tr th:last-child {
    width: 120px;
}

.new-enrollments table.table-large thead tr th,
.new-enrollments table.table-large tbody tr td { 
    border-bottom-color: rgba(255, 255, 255, 0.15);
}

.new-enrollments table.table-large tbody tr:last-child td { 
    border-bottom-width: 0;
}

tr.batch-item svg { 
    opacity: 0.5;
}

tr.batch-item.picked-up td svg {
    color: var(--header-green);
}

#partner-batch-results { 
    
}


#statement-history table tr th:first-child,
#statement-history table tr td:first-child { 
    width: 35%;
}

#statement-history table tr td:last-child a > svg { 
    margin-left: 12px;
}

#statement-details { 
    margin-bottom: 56px;
}

#statement-details .statement-header { 
    display: flex;
    justify-content: space-between;
    align-items: start;
    border-top: var(--border-color) 1px solid;
    padding-top: 24px;
    color: var(--text-color);
}

#statement-details .statement-header > div > div { 
    margin-bottom: 6px;
}

#statement-details .statement-header > .statement-header-right { 
    text-align: right;
}

#statement-details .statement-header > .statement-header-right > h1 { 
    margin-top: 0;
    font-size: 48px;
    color: var(--bright-color);
    margin-bottom: 8px;
}

#statement-details .statement-header > .statement-header-right > div {
    font-size: 12px;
}

#statement-details .statement-header > .statement-header-left > div:first-child { 
    color: var(--bright-color);
}

#statement-details .statement-header > .statement-header-left > div:last-child {
    color: var(--bright-color);
    font-size: 14px;
    padding: 8px 0;
    margin-top: 12px;
    border-top: var(--border-color) 1px dashed;
}

#statement-details .statement-header > .statement-header-left > div.customer-name > a {
    color: var(--text-color);
}

#statement-details .statement-header > .statement-header-left > div.customer-name > a > svg {
    margin-left: 8px;
}

#statement-details .statement-header > .statement-header-left > div.customer-name > a:hover {
    color: var(--bright-color);
}

#statement-details .statement-header > .statement-header-left > div.customer-name > a:hover > svg {
    color: var(--header-green);
}

#statement-details .statement-header > .statement-header-left > div:last-child > .date-time-text { 
    color: var(--header-green);
}

.p-columns .statement-status { 
    position: absolute;
    top: 0;
    right: 0;
    max-width: 220px;
    min-width: 150px;
    text-align: center;
}

.p-columns > .statement-status > a {
    padding: 16px 32px;
    display: block;
    border-radius: 12px;
    background-color: var(--dark-background-color);
}

.p-columns > .statement-status > a.paid {
    opacity: 0.4;
    cursor: default;
}

.p-columns > .statement-status > a:hover { 
    background-color: var(--border-color);
}

#statement-details > .action-panel { 
    font-size: 13px;
}

#statement-details > .action-panel > a {
    display: inline-block;
    margin-right: 12px;
    color: var(--text-color);
    padding: 8px 12px;
    border-radius: 5px;
    background-color: var(--dark-background-color);
}

#statement-details > .action-panel > a:hover {
    color: var(--bright-color);
}

#statement-details > .action-panel > a.archive {
    color: var(--error-red);
}

#statement-details > .action-panel > a.archive:hover {
    color: var(--warning-red);
}

#statement-details > .action-panel > a > svg {
    margin-right: 4px;
}

.statement-body {
    border: var(--border-color) 1px solid;
    margin-top: 24px;
    margin-bottom: 24px;
}

.statement-body > div { 
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    flex-wrap: nowrap;
}

.statement-body > div > span { 
    display: inline-block;
    min-width: 160px;
    border: var(--border-color) 1px solid;
    padding: 12px;
    white-space: nowrap;
}

.statement-body > div > span:last-child { 
    min-width: 200px;
    text-align: right;
}

.statement-body > div > span > label { 
    display: inline-block;
    font-size: 11px;
    color: var(--text-color);
    padding-left: 5px;
}

.statement-body > div > span:first-child { 
    flex: 2;
}

.statement-body > div > span:last-child { 
    
}

.statement-body > div.total { 
    font-weight: bold;
    color: var(--header-green);
    font-size: 24px;
}

div.payment-selector { 
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    border-radius: 8px;
    padding: 12px;
    min-width: 400px;
    cursor: pointer;
}

div.payment-selector > span:last-child { 
    text-align: right;
    font-size: 28px;
    color: var(--yellow);
}

div.payment-selector.selected { 
    background-color: var(--dark-background-color);
}

div.payment-selector:hover {
    background-color: var(--dark-background-color);
}
