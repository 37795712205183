a.copy-clipboard { 
    display: inline-block;
    color: rgba(255, 255, 255, 0.7);
}

a.copy-clipboard svg.copy-clipboard {
    visibility: hidden;
}

a.copy-clipboard:hover { 
    color: white;
    text-decoration: none;
}

a.copy-clipboard.copied svg { 
    color: var(--header-green);
    transition-property: color;
    transition: 300ms ease-in-out;
}

a.copy-clipboard:hover svg.copy-clipboard {
    display: inline-block;
    visibility: unset;
}
