
#reporting-snapshot { 
    display: flex;
    align-content: start;
    align-items: start;
    justify-content: start;
    flex-wrap: nowrap;
}

table#card-brand-list { 
    width: 100%;
}

table#card-brand-list tr th,
table#card-brand-list tr td {
    width: 25%;
}

table#card-brand-list tr th:first-child,
table#card-brand-list tr td:first-child { 
    width: 50%;
}

.pie-chart,
.pie-chart text { 
    color: white;
    display: inline-block;
    margin: 12px;
}

.pie-chart text {
    fill: rgba(255, 255, 255, 0.55);
    font-weight: bold;
    font-size: 11px;
}

.pie-chart .american-express text {
    background-color: red;
}

.pies { 
    display: flex;
    align-content: start;
    align-items: start;
    justify-content: start;
    margin-left: 32px;
}

.pies div {
    margin-left: 32px;
    padding: 12px;
}

.bar-chart-graph-footer,
.card-brand-line-graph-footer { 
    font-size: 13px;
    margin-top: 12px;
    text-align: right;
    color: rgba(255, 255, 255, 0.75);
}

.bar-chart-graph-container,
.card-brand-graph-container { 
    border-radius: 12px;
    height: 272px;
    width: 100%;
    display: block;
    margin-top: 12px;
    background-color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.bar-chart-graph-container canvas { 
    height: 272px;
}

.bar-chart-graph h3,
.card-brand-line-graph h3 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 48px;
}

#reporting-main .card-brand-line-graph h3 { 
    margin-top: 48px;
}

h3.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

h3.controls label { 
    display: inline-block;
    margin-right: 24px;
}

h3 span.line-controls { 
    display: inline-block;
    margin-left: 12px;
    font-size: 12px;
}

h3 span.line-controls.provider {
    align-self: end;
}

h3 span#home-line-controls a {
    display: inline-block;
    margin-right: 12px;
    padding: 8px 16px;
    /* background-color: rgba(255, 255, 255, 0.05); */
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.55);
}

h3 span#home-line-controls a.selected {
    background-color: rgba(0, 0, 0, 0.25);
    color: var(--header-green);
}

h3 span#home-line-controls a:hover {
    background-color: rgba(0, 0, 0, 0.25);
    color: #00fe35;
}

#line-pie-graph-container { 
    display: flex;
    align-content: start;
    align-items: start;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 32px;
}

#line-pie-graph-container #line-graph-container {
    display: inline-block;
    width: 70%;
}

#line-pie-graph-container #pie-graph-container {
    display: inline-block;
    width: 30%;
    padding-top: 12px;
}

#line-pie-graph-container #pie-graph-container .round24 { 
    padding: 16px;
    display: inline-block;
}

table tfoot.total { 
    background-color: #4fA13222;
}

table tfoot td { 
    font-weight: bold;
}