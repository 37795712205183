
#customer-support { 
    width: 100%;
    display: block;
}

#customer-support h2 svg.ticket-type-icon { 
   margin-left: 12px;
}

#customer-support .body { 
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0 24px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    margin-bottom: 24px;
}

#customer-support .body.ticket-selected {
    animation: bodyFade 4s linear;
}

@keyframes bodyFade {
    from {
        background-color: var(--selected-content-color);
    }
    to {
        background-color: rgba(0, 0, 0, 0.2);
    }
}

#customer-support .body .subject { 
    padding: 24px 0 16px 0;
    font-size: 20px;
    color: white;
    font-weight: bold;
    width: 100%;
    border-bottom: rgba(255, 255, 255, 0.1) dashed 1px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

#customer-support .body .subject > *:last-child { 
    margin-left: auto;
    font-size: 13px;
}

#customer-support .body .subject > *:last-child > a { 
    display: inline-block;
    margin-left: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    color: var(--header-green);
}

#customer-support .body .subject > *:last-child > a:hover { 
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--paylani-green);
}

#customer-support .body .subject > *:last-child > a > svg { 
    margin-right: 5px;
}

#customer-support .body .subject > span { 
    font-weight: normal;
    color: rgba(255, 255, 255, 0.55);
    display: inline-block;
}

#customer-support .body .subject > span:first-child {
    margin-right: 12px;
}

#customer-support .body p {
    color: rgba(255, 255, 255, 0.75);
    font-size: 18px;
    min-height: 180px;
    padding-left: 32px;
    padding-top: 16px;
}

#customer-support .body .date { 
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    border-top: rgba(255, 255, 255, 0.1) dashed 1px;
    padding-top: 16px;
    width: 100%;
    display: flex;
    gap: 24px;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

#customer-support .body .date > *:last-child { 
    margin-left: auto;
}

#customer-support .body .date span.from { 
    color: white;
}

#support-menu { 
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    font-size: 13px;
    gap: 16px;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: var(--border-color) dashed 1px;
}

#support-menu > span:first-child {
    margin-right: auto;
}

#support-menu > span > a {
    border: var(--border-color) 1px solid;
    display: inline-block;
    padding: 6px 12px;
    border-radius: 5px;
}

#support-menu > span > a > svg:first-child { 
    margin-right: 6px;
}

.connected, .not-connected {
    font-size: 12px;
}

.connected > .google-account {
    padding: 6px;
    background-color: var(--dark-background-color);
    border-radius: 4px;
}

.connected > .google-account.add {
    color: var(--yellow);
}

.connected > .google-account.add > svg {
    margin-left: 6px;
}

.connected > .google-account:hover {
    color: var(--paylani-green);
}

.not-connected > a {
    font-size: 16px;
    color: var(--paylani-green-faded);
    padding: 6px 10px;
    background-color: var(--dark-background-color);
    border-radius: 4px;
}

.not-connected > a:hover {
    color: var(--paylani-green);
}

.not-connected > a > svg {
    margin-left: 8px;
}

span#filtering { 
    position: relative;
}

.form input[type="text"].filter-text { 
    width: 420px;
    margin-bottom: 0;
    padding-left: 32px;
}

span#filtering > span.search-icon { 
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    color: var(--text-color-faded);
}

.collapsed-ticket { 
    font-size: 15px;
    white-space: nowrap;
    overflow: clip;
    text-overflow: ellipsis;
    margin-right: 12px;
}

.collapsed-ticket {
    border: var(--yellow-faded) dashed 1px;
    padding: 0 8px;
    margin: 5px 0 5px 8px;
    border-radius: 8px;
}

.collapsed-ticket:hover {
    background-color: var(--dark-background-color);
}

.collapsed-ticket:last-child { 
    margin-bottom: 16px;
}

.collapsed-ticket:first-child {
    margin-top: 12px;
}

.collapsed-ticket > a { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 100%;
    gap: 16px;
    padding: 8px 0;
}

.collapsed-ticket > a > span { 
    display: inline-block;
}

.collapsed-ticket > a > span:first-child { 
    --sz: 172px;
    width: var(--sz);
    max-width: var(--sz);
    min-width: var(--sz);
}

.collapsed-ticket > a > span:last-child { 
    color: var(--text-color);
}

.collapsed-ticket > a > .preview {
    white-space: nowrap;
    overflow: clip;
    text-overflow: ellipsis;
    font-size: 12px;
}

h2.support-ticket-header { 
    
}

h2.support-ticket-header .action-buttons { 
    margin-left: auto;
    font-size: 14px;
}

h2.support-ticket-header .action-buttons svg { 
    margin-right: 8px;
}

.thread-summary {
    font-size: 14px;
    color: var(--yellow);
    padding: 12px 24px;
    margin-bottom: 12px;
    margin-top: 12px;
    background-color: var(--dark-background-color);
    border-radius: 8px;
}

.thread-summary > a {
    color: var(--yellow);
}

.reply-form, .form { 
    width: 100%;
}

.reply-form > .reply-buttons { 
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    gap: 32px;
    align-items: center;
    align-content: center;
}

.form > .reply-form > .reply-as { 
    margin-bottom: 16px;
}

.form > .reply-form > textarea { 
    width: 100%;
    height: 140px;
    min-width: 200px;
    max-width: 1200px;
    display: block;
}

table#tickets-list-table tr td,
table#tickets-list-table tr td a {
    white-space: nowrap;
    font-weight: normal;
    overflow: clip;
    text-overflow: ellipsis;
}

table#tickets-list-table > tbody > tr > td:first-child,
table#tickets-list-table > thead > tr > th:first-child { 
    --name-width: calc((100% - 320px) / 3);
    width: var(--name-width);
    min-width: 200px !important;
    max-width: var(--name-width);
}

table#tickets-list-table > tbody > tr > td:last-child,
table#tickets-list-table > thead > tr > th:last-child {
    --date-width: 220px;
    max-width: var(--date-width);
    min-width: var(--date-width);
    width: var(--date-width);
}

table#tickets-list-table tr.read td,    
table#tickets-list-table tr.read td a {
    color: rgba(255, 255, 255, 0.5);
}

table tr.read td a:hover {
    color: white;
}

table tr.unread td.clipped,
table tr.read td.clipped {
    text-overflow: ellipsis;
    overflow: clip;
}

table#tickets-list-table tr.unread td,
table#tickets-list-table tr.unread td > a {
    font-weight: bold;
    color: white;
}
