
.search-box > input::placeholder {
    color: var(--text-color-faded);
    font-weight: lighter;
}

.search-box > input[type="text"] {
    width: 100%;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: lighter;
}

#master-search-container {
    height: 80px;
    position: relative;
}

.search-results {
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    border: var(--border-color) 1px solid;
    background-color: black;
    width: 100%;
    border-radius: 12px;
    min-height: 70px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 32;
}

.search-results > ul {
    display: block;
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
}

.search-results > ul:last-child {
    padding-bottom: 24px;
}

.search-results > div {
    display: block;
    margin-bottom: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: var(--border-color) 1px dashed;
}

.search-results > div:last-child {
    border-bottom-width: 0;
    margin-bottom: 12px;
}

.search-results > div:first-child {
    margin-top: 12px;
}

.search-results > div.search-result-title {
    font-weight: bold;
    padding-top: 0;
    color: var(--paylani-green);
}

.search-results > div > a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.search-results > div > a:hover > * {
}

.search-results > div > a > span > h4 {
    margin: 0;
    padding-bottom: 4px;
}

.search-results > div > a > span:first-child {
    width: 64px;
    align-items: center;
    text-align: center;
    display: inline-block;
}

.search-results > div > a > span:first-child svg {
    min-height: 24px;
}

.search-results > div > a > span:first-child img {
    object-fit: cover;
    border-radius: 50%;
    border: rgba(100, 100, 100, 0.6) 1px solid;
    width: 32px;
    height: 32px;
}

.search-results > div > a > span > p {
    margin: 0;
    font-size: 12px;
}

.search-results > div span.preview {
    display: block;
    font-size: 12px;
    color: var(--yellow);
    font-weight: bold;
}

.search-results > div > a:hover h4,
.search-results > div > a:hover > span:first-child svg,
.search-results > div > a:hover p,
.search-results > div > a:hover span {
    color: var(--text-color);
}

.search-results > div:last-child {
    margin-bottom: 0;
}

.search-results div > strong {
    text-align: center;
    display: block;
    font-weight: normal;
}

#master-search-container .search-results {

}

#master-search-input {
    font-size: 20px;
    font-weight: lighter;
}

#master-search-input::placeholder {
    color: var(--text-color-faded);
    font-weight: lighter;
}
