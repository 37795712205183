
div#message-details { 
    
}

#message-details .form textarea { 
    height: 220px;
    width: 800px;
    display: block;
}

#message-details .form #subject { 
    width: 800px;
}

#message-details p { 
    margin-bottom: 64px;
}

#message-details p a.tag { 
    display: inline-block;
    padding: 4px 8px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    margin-left: 12px;
    font-size: 12px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

#message-details p a.tag:hover { 
    background-color: rgba(255, 255, 255, 0.25);
    color: white;
}